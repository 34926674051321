import React from 'react';
import '../index.css';
import Anime from 'react-anime';
import { Link } from 'react-router-dom';

import Image from '../assets/img/eu.jpg';

const flipInX = {
  opacity: [0, 1],
  translateX: [-100, 0],
  rotateX: [-90, 0],
  easing: 'easeOutCubic',
  duration: 1000,
  separator: ",",
  speed: 3000
};

function Servicos() {
  return (
    <div>

<div>
      <center>
    <div className='container-max'>
    <section id='portfolio' className='section bg-primary min-h-[1400px]'>
      <div className='hero-container'>
        <div className='flex flex-col items-center text-center'>
          <h2 className='section-title before:content-portfolio relative before:absolute before:opacity-40 before:-top-[2rem] before:-left-3/4 before:hidden before:lg:block'>
            Serviços
          </h2>
          <p className='subtitle'>
            Aqui você pode escolher algum serviço pronto, caso queira um site diferente, clique em CONTATO.
          </p>
        </div>


		<section className='ser'>
    <div class="container">
        <div class="pricing-plan mrgt15x mrgb15x">
            <div class="section-heading blue-border text-center mrgb4x">
                <h3 class="letterspace4x">Selecione a Versão do Tema Madara</h3>
                <p>Cada versão tem algo diferente, escolha uma que mais se encaixe nos seus planos.</p>
				</div>

        <div class="pricing-content text-center">
            <div class="tab-content mrgt8x">
                <div id="monthly" class="tab-pane fade in active">
                    <div class="row">
                        <div class="col-md-4 col-sm-6">
                            <div class="outer-border">
                                <div class="pricing-table">
                                    <div class="upper-detail">
                                        <h5 class="plan-name letterspace4x">BÁSICO</h5>
                                        <p>O tema básico do madara, sem mudanças</p>
                                        <div class="plan-price">
                                            <h1><sup>R$</sup>100</h1>
                                        </div>
                                    </div>
                                    <div class="lower-detail">
                                        <ul>
                                            <li>Apenas Plugins Básicos</li>
                                            <li>Login/Registro</li>
                                            <li>Design Original</li>
											<li>Uploud de Manga, Webtoon,<br/>Anime e Novel</li>
                                        </ul>
                                        <a href="#" class="btn-get">ESCOLHER</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="outer-border">
                                <div class="pricing-table">
                                    <div class="upper-detail">
                                        <div class="new-tag">
                                            <span>Novo!</span>
                                        </div>
                                        <h5 class="plan-name letterspace4x">POPULAR</h5>
                                        <p>Tema com algumas mudanças no design,<br/>como no header e na página do mangá</p>
                                        <div class="plan-price">
                                            <h1><sup>R$</sup>420</h1>
                                        </div>
                                    </div>
                                    <div class="lower-detail">
									<ul>
                                            <li>Plugins Adicionais</li>
                                            <li>Login/Registro</li>
                                            <li>Algumas mudanças no design</li>
											<li>Uploud de Manga, Webtoon,<br/>Anime e Novel</li>
                                        </ul>
                                        <a href="#" class="btn-get">ESCOLHER</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="outer-border">
                                <div class="pricing-table">
                                    <div class="upper-detail">
                                        <h5 class="plan-name letterspace4x">PREMIUM</h5>
                                        <p>Tema totalmente editado.</p>
                                        <div class="plan-price">
                                            <h1><sup>R$</sup>790</h1>
                                        </div>
                                    </div>
                                    <div class="lower-detail">
									<ul>
                                            <li>Plugins Adicionais</li>
                                            <li>Login/Registro</li>
                                            <li>Design Diferente</li>
											<li>Uploud de Manga, Webtoon,<br/>Anime e Novel</li>
                                        </ul>
                                        <a href="#" class="btn-get">ESCOLHER</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
      </div>
    </section>
    </div></center>
      </div>
    </div>
  );
}

export default Servicos;