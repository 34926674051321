import React from 'react';

function Footer() {
  return (
    <footer id="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="copyright">
              &copy; Copyright <strong>Giovanni Sant Ana</strong>.
              Todos os direitos reservados.
            </div>

            <div className="credits">
              Coded And Developed By{" "}
              <a href="">Giovanni Sant Ana</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;