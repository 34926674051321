// App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";
import Portfolio from "./components/Portfolio";
import Servicos from "./components/Servicos";
import Sobre from "./components/Sobre";
import Contato from "./components/Contato";

function Home() {
  return (
    <>
      <Header />
      <Main />
      <Footer />
    </>
  );
}
function Portfolioz() {
  return (
    <>
      <Header />
      <Portfolio />
      <Footer />
    </>
  );
}
function Servicoss() {
  return (
    <>
      <Header />
      <Servicos />
      <Footer />
    </>
  );
}
function Contat() {
  return (
    <>
      <Header />
      <Contato />
      <Footer />
    </>
  );
}
function Sobr() {
  return (
    <>
      <Header />
      <Sobre />
      <Footer />
    </>
  );
}
function App() {
  return (
      <Router>
    <div className='bg-black relative'>
      <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/Portfolio' element={<Portfolioz/>} />
      <Route path='/Servicos' element={<Servicoss/>} />
      <Route path='/Sobre' element={<Sobr/>} />
      <Route path='/Contato' element={<Contat/>} />
      </Routes>
    </div>
  </Router>
      
  );
}

export default App;