import React from 'react';
import '../index.css';
import Anime from 'react-anime';
import { Link } from 'react-router-dom';

import Image from '../assets/img/eu.jpg';

const flipInX = {
  opacity: [0, 1],
  translateX: [-100, 0],
  rotateX: [-90, 0],
  easing: 'easeOutCubic',
  duration: 1000,
  separator: ",",
  speed: 3000
};

const Sobre = () => {
  return (
    <div>

    <div>
          <center>
        <div className='container-max'>
        <section id='portfolio' className='section bg-primary min-h-[1400px]'>
          <div className='hero-container'>
            <div className='flex flex-col items-center text-center'>
              <h2 className='section-title before:content-portfolio relative before:absolute before:opacity-40 before:-top-[2rem] before:-left-3/4 before:hidden before:lg:block'>
                Sobre
              </h2>
              <p className='subtitle'>
              </p>
            </div>
    
    
            <section className='section bg-secondary' id='about'>
      <div className='container mx-auto'>
        <div className=''>
          <img
            className='object-cover h-full w-[566px] md:mx-auto lg:mx-0 rounded-2xl'
            src={Image}
            alt=''
          />
          <div className='flex flex-col items-center text-center lg:items-start lg:text-left'>
            <div className='flex flex-col'>
              <h2 className='text-3xl lg:text-4xl font-medium lg:font-extrabold mb-3 before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block'>
                Giovanni Sant'Ana
              </h2>
              <p className='mb-4 text-accent'>
              Desenvolvedor Web Frontend Freelancer
              </p>
              <hr className='mb-8 opacity-5' />
              <p className='mb-8'>
              Olá, meu nome é Giovanni M. Sant Ana e sou um Desenvolvedor Web Frontend Freelancer.
              Meu objetivo é criar soluções web dinâmicas e responsivas que proporcionem aos usuários uma
              experiência de navegação suave e intuitiva. Tenho habilidades sólidas em HTML, CSS, JavaScript
              e bibliotecas de frontend populares, como React e Vue.js, o que me permite criar interfaces
              elegantes e interativas.<br />
                <br />
                Minha paixão por aprender novas tecnologias e tendências me mantém atualizado com as últimas inovações no campo.
                Acredito firmemente que o desenvolvimento web é uma jornada constante de aprendizado e aprimoramento,
                e estou sempre em busca de novos desafios e oportunidades para crescer como profissional.
              </p>
            </div>
            <button className='btn btn-md bg-accent hover:bg-secondary-hover transition-all'>
              Entre em contato
            </button>
          </div>
        </div>
      </div>
    </section>
          </div>
        </section>
        </div></center>
          </div>
        </div>
  );
};

export default Sobre;
