import React from 'react';

import Image from '../assets/img/eu.jpg';

const Project = ({ item }) => {
  return (
    <div key={item.id} className='flex flex-col items-center text-center'>
    <ul class="cards">
      <li>
        <a href="" class="card">
          <img src={item.image} class="card__image" alt="" />
          <div class="card__overlay">
            <div class="card__header">
              <svg class="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>                     
              <img class="card__thumb" src={Image} alt="" />
              <div class="card__header-text">
                <h3 class="card__title">{item.name}</h3>            
                <span class="card__status">{item.category}</span>
              </div><a href={item.link}><button id="btn-site" className='btn btn-md bg-accent hover:bg-secondary-hover transition-all'>{item.buttontext}</button></a>
            </div>
            <p class="card__description"> {item.description}</p>
          </div>
        </a>      
      </li>
    </ul>
        </div>
  );
};

export default Project;
