import React from 'react';
import '../index.css';

// import contact data
import { contact } from '../data';

const Contato = () => {
    return (
    <div>

<div>
      <center>
    <div className='container-max'>
    <section id='portfolio' className='section bg-primary min-h-[1400px]'>
      <div className='hero-container'>
        <div className='flex flex-col items-center text-center'>
          <h2 className='section-title before:content-portfolio relative before:absolute before:opacity-40 before:-top-[2rem] before:-left-3/4 before:hidden before:lg:block'>
            Contato
          </h2>
          <p className='subtitle'>
            Entre em contato comigo, caso tenha alguma dúvida.
          </p>
        </div>

        <div
          className='flex flex-col lg:gap-x-8 lg:flex-row' >
          <div
            className='flex flex-1 flex-col items-start space-y-8 mb-12 lg:mb-0 lg:pt-2'>
            {contact.map((item, index) => {
              const { icon, title, subtitle, description } = item;
              return (
                <div className='flex flex-col lg:flex-row gap-x-4' key={index}>
                  <div className='text-accent rounded-sm w-14 h-14 flex items-start justify-center mt-2 mb-4 lg:mb-0 text-2xl'>
                    {icon}
                  </div>
                  <div>
                    <h4 className='font-body text-xl mb-1'>{title}</h4>
                    <p className='mb-1 text-paragraph'>{subtitle}</p>
                    <p className='text-accent font-normal '>{description}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <form
            className='space-y-8 w-full max-w-[780px]'
          >
            <div className='flex gap-8'>
              <input className='input' type='text' placeholder='Nome' />
              <input className='input' type='email' placeholder='Email' />
            </div>
            <input className='input' type='text' placeholder='Assunto' />
            <textarea
              className='textarea'
              placeholder='Mensagem'
            ></textarea>
            <button className='btn btn-lg bg-accent hover:bg-secondary-hover'>
              Enviar
            </button>
          </form>
        </div>
      </div></section></div>
    </center></div></div>
  );
}

export default Contato;