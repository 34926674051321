import React from 'react';
import '../index.css';
import Projects from './Projects';
import Anime from 'react-anime';
import { Link } from 'react-router-dom';


import Image from '../assets/img/eu.jpg';

const flipInX = {
  opacity: [0, 1],
  translateX: [-100, 0],
  rotateX: [-90, 0],
  easing: 'easeOutCubic',
  duration: 1000,
  separator: ",",
  speed: 3000
};


const Portfolio = () => {
  return (
    <div>
      <center>
    <div className='container-max'>
    <section id='portfolio' className='section bg-primary min-h-[1400px]'>
      <div className='hero-container'>
        <div className='flex flex-col items-center text-center'>
          <h2 className='section-title before:content-portfolio relative before:absolute before:opacity-40 before:-top-[2rem] before:-left-3/4 before:hidden before:lg:block'>
            Trabalhos Recentes
          </h2>
          <p className='subtitle'>
            Aqui está alguns dos meus últimos trabalhos.
          </p>
        </div>
        <Projects />
      </div>
    </section>
    </div></center>
      </div>
  );
};

export default Portfolio;
